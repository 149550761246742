import React, { useEffect, useState } from 'react';
import EmailDisplay from './EmailDisplay';
import { DepreciationIconOverview, PropertyIcon, PropertySettlementIconOverview, RentIcon, UsersIconNoMargin } from '../../assets/icons/index';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  approveApprovalRequestAsync,
  getUserDetailsAsync,
  rejectApprovalRequestAsync,
  selectUserDetails,
  selectUserDetailsLoading,
} from '../../container/reducers/slices/UserOverview/UserOverviewSlice';
import { Avatar, Button, Card, Divider, notification, Spin, Tag } from 'antd';
import { ApartmentOutlined, CommentOutlined, CopyOutlined, DollarOutlined, LeftOutlined, MailOutlined, PhoneOutlined, TagsOutlined, UserOutlined } from '@ant-design/icons';
import './BasiqMultipleAccounts.css';
import CommentsModal from './CommentsModal';
import { ChannelPartnerType, UserStatus, UserTypes } from '../../components/common/userTypes';
import dayjs from 'dayjs';
import DiscountModal from './DiscountModal';

const Overview = () => {
  const { id } = useParams();

  const [openComment, setOpenComment] = useState(false);
  const dispatch = useDispatch();

  const [openDiscount, setOpenDiscount] = useState(false);

  const userInfo = useSelector(selectUserDetails);
  const loading = useSelector(selectUserDetailsLoading);

  const navigate = useNavigate();

  const isTaxAccountant = userInfo?.user_type === UserTypes.TAXACCOUTANT;

  const property_investor = userInfo?.user_type === UserTypes.USER;

  const isMortgageBroker = userInfo?.user_type === UserTypes.CHANNEL_PARTNER;

  useEffect(() => {
    if (id) {
      dispatch(getUserDetailsAsync(id));
    }
  }, [dispatch, id]);

  const descriptionItems = [
    {
      key: 'name',
      label: <UserOutlined style={{ color: 'black' }} />,
      children: <EmailDisplay email={userInfo?.name} />,
    },
    {
      key: 'email',
      label: <MailOutlined style={{ color: 'black' }} />,
      children: <EmailDisplay email={userInfo?.email} />,
    },
    {
      key: 'phoneNumber',
      label: <PhoneOutlined style={{ color: 'black', transform: 'rotate(90deg)' }} />,
      children: <EmailDisplay email={userInfo?.phone_number} />,
    },
  ];

  if (userInfo?.virtual_email) {
    const virtualEmailItem = {
      key: 'virtualEmail',
      label: <MailOutlined style={{ color: 'black' }} />,
      children: <EmailDisplay email={userInfo?.virtual_email} />,
    };

    descriptionItems.splice(descriptionItems.length - 1, 0, virtualEmailItem);
  }

  const belowItems = [];

  if (userInfo?.user_type === UserTypes.USER) {
    if (userInfo?.tax_accountant_id) {
      belowItems.push({
        key: 'Tax Accountant',
        label: 'Tax Accountant',
        children: userInfo?.tax_accountant_name,
      });
    }
    if (userInfo?.mortgage_broker_id) {
      belowItems.push({
        key: 'Mortgage Broker',
        label: 'Mortgage Broker',
        children: userInfo?.mortgage_broker_name,
      });
    }
  }

  if (userInfo?.user_type === UserTypes.CHANNEL_PARTNER && userInfo?.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER) {
    if (userInfo?.company_name) {
      belowItems.push({
        key: 'Company Name',
        label: 'Company Name',
        children: userInfo?.company_name,
      });
    }
    if (userInfo?.crn_number) {
      belowItems.push({
        key: 'CRN Number',
        label: 'CRN Number',
        children: userInfo?.crn_number,
      });
    }
  }
  belowItems.push({
    key: 'Created At',
    label: 'Created At',
    children: dayjs(userInfo?.created_at).format('DD-MM-YYYY hh:mm A'),
  });

  const stats = [
    {
      title: 'Total Properties',
      value: userInfo?.property_count || 0,
      icon: <PropertyIcon />,
    },
    {
      title: 'Rent Summary',
      value: userInfo?.rent_summary_count || 0,
      icon: <RentIcon />,
    },
    {
      title: 'Depreciation',
      value: userInfo?.depreciation_count || 0,
      icon: <DepreciationIconOverview />,
    },
    {
      title: 'Settlement',
      value: userInfo?.settlement_count || 0,
      icon: <PropertySettlementIconOverview />,
    },
  ];

  const financialPlannerStats = [
    {
      title: 'Total Clients',
      value: userInfo?.client_count || 0,
      icon: <UsersIconNoMargin />,
    },
    {
      title: 'Total Properties',
      value: userInfo?.property_count || 0,
      icon: <PropertyIcon />,
    },
    {
      title: 'Referred Users',
      value: userInfo?.referred_count || 0,
      icon: (
        <ApartmentOutlined
          style={{
            fontSize: 20,
            marginTop: 12,
            padding: 0,
          }}
        />
      ),
    },
    {
      title: 'Referral Code',
      value: userInfo?.referral_code || '-',
      icon: (
        <DollarOutlined
          style={{
            fontSize: 20,
            marginTop: 12,
            padding: 0,
          }}
        />
      ),
    },
  ];

  const mortgageBrokerStats = [
    {
      title: 'Total Clients',
      value: userInfo?.client_count || 0,
      icon: <UsersIconNoMargin />,
    },
    {
      title: 'Total Invited Clients',
      value: userInfo?.onboard_count || 0,
      icon: (
        <ApartmentOutlined
          style={{
            fontSize: 20,
            marginTop: 12,
            padding: 0,
          }}
        />
      ),
    },
    {
      title: 'Total Properties',
      value: userInfo?.property_count || 0,
      icon: <PropertyIcon />,
    },
    {
      title: 'Referral Code',
      value: userInfo?.referral_code || '-',
      icon: (
        <DollarOutlined
          style={{
            fontSize: 20,
            marginTop: 12,
            padding: 0,
          }}
        />
      ),
    },
  ];

  const getStatsRenderList = () => {
    if (isTaxAccountant) {
      return financialPlannerStats;
    } else if (property_investor) {
      return stats;
    } else if (isMortgageBroker) {
      return mortgageBrokerStats;
    }
  };

  const approveApprovalRequest = async () => {
    try {
      const fp_id = userInfo?.financial_planner?.id;
      const res = await dispatch(approveApprovalRequestAsync(fp_id));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(getUserDetailsAsync(id));
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Failure',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const rejectApprovalRequest = async () => {
    try {
      const fp_id = userInfo?.financial_planner?.id;
      const res = await dispatch(rejectApprovalRequestAsync(fp_id));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(getUserDetailsAsync(id));
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Failure',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    notification.success({
      message: 'Success',
      description: 'Copied.',
    });
  };

  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center  align-items-center">
          <Spin spinning={loading} size="large" />
        </div>
      ) : (
        <div className="d-flex flex-column gap-3">
          {(isTaxAccountant || isMortgageBroker) && (
            <div className="d-flex justify-content-start align-items-center gap-2">
              <Button onClick={() => navigate(`/user/${isTaxAccountant ? 'tax-accountant' : 'mortgage-broker'}`)} icon={<LeftOutlined />} />
              <h5 className="p-0 m-0">User Details </h5>
            </div>
          )}
          <div className="p-3 d-flex flex-column gap-3 bg-white">
            <div className="d-flex justify-content-start gap-3">
              <div className="overviewProfilePicture d-flex gap-3">
                {userInfo?.profile_image_path || userInfo?.company_logo ? (
                  <Avatar src={userInfo?.profile_image_path || userInfo?.company_logo} size={148} shape={userInfo?.user_type === UserTypes.USER ? 'circle' : 'square'} />
                ) : (
                  <Avatar size={128} shape={userInfo?.user_type === UserTypes.USER ? 'circle' : 'square'} icon={<UserOutlined style={{ fontSize: '48px' }} />} />
                )}
                <Divider
                  type="vertical"
                  style={{
                    height: 150,
                  }}
                />
              </div>

              <div className="w-100 d-flex flex-column justify-content-between">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0">{userInfo?.name}</h4>
                    <Button icon={<CopyOutlined />} size="small" onClick={() => copyToClipboard(userInfo?.name)} style={{ marginLeft: 8 }} />
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <Button htmlType="button" ghost type="primary" icon={<TagsOutlined />} onClick={() => setOpenDiscount(true)}>
                      Discount
                    </Button>
                    <Button htmlType="button" type="primary" icon={<CommentOutlined />} onClick={() => setOpenComment(true)}>
                      Admin Comments
                    </Button>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-4 ">
                  <div className="d-flex align-items-center gap-2">
                    <MailOutlined style={{ color: '#56aaaa' }} />
                    <EmailDisplay margin={false} email={userInfo?.email} />
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <PhoneOutlined style={{ color: '#56aaaa', transform: 'rotate(90deg)' }} /> <EmailDisplay margin={false} email={userInfo?.phone_number} />
                  </div>
                  {userInfo?.virtual_email && (
                    <div className="d-flex align-items-center gap-2">
                      <MailOutlined style={{ color: '#56aaaa' }} />
                      <EmailDisplay margin={false} email={userInfo?.virtual_email} />
                    </div>
                  )}
                </div>
                <div className="d-flex align-items-center  gap-4 ">
                  {belowItems.map((item, index) => (
                    <div key={index} className="d-flex flex-column">
                      <span
                        style={{
                          color: '#aba1a1',
                          fontWeight: 'bolder',
                        }}
                      >
                        {item.label}
                      </span>
                      <p className="m-0">{item.children}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Divider />
            <div>
              <div className="userStatsPreview">
                {getStatsRenderList().map((stat, index) => (
                  <div key={index}>
                    <div className="userStats p-3">
                      <h4>{stat.value}</h4>
                      <div className="d-flex justify-content-between align-items-center icon_11">
                        <span className="overviewCardLabel">{stat.title}</span>
                        <p className={`m-0 icon_${index + 1}`}>{stat.icon}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            {userInfo?.financial_planner && (
              <div>
                <Card
                  tit0le="Financial Planner"
                  extra={
                    <>
                      {userInfo?.financial_planner?.approved_status === UserStatus.PENDING ? (
                        <div className="d-flex align-items-center gap-2">
                          <Button onClick={() => rejectApprovalRequest()} className="Delete_btn">
                            Decline
                          </Button>
                          <Button onClick={() => approveApprovalRequest()} type="primary">
                            Approve
                          </Button>
                        </div>
                      ) : userInfo?.financial_planner?.approved_status === UserStatus.ACTIVE ? (
                        <div className="d-flex align-items-center gap-2">
                          <Button onClick={() => rejectApprovalRequest()} className="Delete_btn">
                            Decline
                          </Button>
                          <Tag color="green">Approved</Tag>
                        </div>
                      ) : (
                        <Tag color="red">Declined</Tag>
                      )}
                    </>
                  }
                >
                  <div className="w-100">
                    <div className="d-flex gap-2">
                      <span>License Entity :</span>
                      <h6>{userInfo?.financial_planner?.license_entity}</h6>
                    </div>
                    <div className="d-flex gap-2">
                      <span>Authorise Representative Number :</span>
                      <p className="mb-1 text-muted">{userInfo?.financial_planner?.authorize_representative_number}</p>
                    </div>
                    <div className="d-flex gap-2">
                      <span>Authorised Representative Name :</span>
                      <p className="mb-1 text-muted">{userInfo?.financial_planner?.authorize_representative}</p>
                    </div>
                  </div>
                </Card>
              </div>
            )}
          </div>
        </div>
      )}
      <CommentsModal openComment={openComment} setOpenComment={setOpenComment} />
      <DiscountModal openDiscount={openDiscount} setOpenDiscount={setOpenDiscount} />
    </div>
  );
};

export default Overview;
