import { Button, DatePicker, Form, Input, Spin, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { PencilIcon } from '../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { ReloadOutlined } from '@ant-design/icons';
import { GetCompleteSummaryList } from '../../container/actions/RentSummary/getCompleteSummary.action';
import { getPropertyTypeLabel } from '../../utils/ConstLabel';
import { debounce } from 'lodash';

const RentCompleted = () => {
  const [completeSummaryData, setCompleteSummaryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();

  // Initialize 'filter' as null to prevent initial API call
  const [filter, setFilter] = useState(null);

  const [inputValue, setInputValue] = useState('');
  const [paginationCurrent, setPaginationCurrent] = useState(1);

  // First useEffect: Initialize filters from localStorage
  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem('rentCompletedFilter'));
    const storedCurrentPage = JSON.parse(localStorage.getItem('rentCompletedCurrentPage'));

    if (storedFilter) {
      filterForm.setFieldsValue({
        Search: storedFilter?.searchSlug || '',
        startDate: storedFilter?.startDate ? dayjs(storedFilter.startDate) : null,
        endDate: storedFilter?.endDate ? dayjs(storedFilter.endDate) : null,
      });
      setFilter(storedFilter);
    } else {
      // Initialize with default filter values if none are stored
      setFilter({ startDate: '', endDate: '', searchSlug: '' });
    }

    if (storedCurrentPage) {
      setPaginationCurrent(storedCurrentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Second useEffect: Fetch data when 'filter' is set
  useEffect(() => {
    if (filter !== null) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const fetchData = async () => {
    setLoading(true);
    const { startDate, endDate, searchSlug } = filter;
    const res = await dispatch(
      GetCompleteSummaryList({
        startDate: startDate || '',
        endDate: endDate || '',
        searchSlug: searchSlug || '',
      }),
    );
    if (res?.payload?.success) {
      setCompleteSummaryData(res.payload.result);
    } else {
      setCompleteSummaryData([]);
    }
    setLoading(false);
  };

  const startDateChange = (value) => {
    setPaginationCurrent(1);

    if (value === null) {
      setFilter({ ...filter, startDate: '' });
      localStorage.setItem('rentCompletedFilter', JSON.stringify({ ...filter, startDate: '' }));
      return;
    }

    if (value && dayjs(value).isValid() && filter.endDate && dayjs(filter.endDate) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be less than End Date.',
      });
      filterForm.setFieldsValue({ startDate: null });
      return;
    }

    const newStartDate = value ? dayjs(value).format('YYYY-MM-DD') : '';
    setFilter({ ...filter, startDate: newStartDate });
  };

  const endDateChange = (value) => {
    setPaginationCurrent(1);

    if (value === null) {
      setFilter({ ...filter, endDate: '' });
      localStorage.setItem('rentCompletedFilter', JSON.stringify({ ...filter, endDate: '' }));
      return;
    }

    if (value && dayjs(value).isValid() && filter.startDate && dayjs(filter.startDate) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'End Date should be greater than Start Date.',
      });
      filterForm.setFieldsValue({ endDate: null });
      return;
    }

    const newEndDate = value ? dayjs(value).format('YYYY-MM-DD') : '';
    setFilter({ ...filter, endDate: newEndDate });
  };

  const SearchSlug = debounce((e) => {
    console.log('SearchSlug ~ e:', e?.target?.value);
    if (e?.target?.value === '' || e?.target?.value === null) {
      localStorage.setItem('rentCompletedFilter', JSON.stringify({ ...filter, searchSlug: '' }));
    }

    setPaginationCurrent(1);
    setInputValue(e.target.value);
    const values = e.target.value.trim();
    setFilter({ ...filter, searchSlug: values });
  }, 300);

  const RentCompleteColumn = [
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      width: '25%',
      render: (_, record) => {
        return record?.user?.email || '';
      },
    },
    {
      title: 'Property Name',
      dataIndex: 'street_number',
      key: 'name',
      width: '20%',
      render: (_, record) => {
        return `${record?.transaction?.property?.street_number || ''}${record?.transaction?.property?.street_name ? ' ' + record?.transaction?.property?.street_name : ''}`;
      },
    },
    {
      title: 'Date',
      dataIndex: 'max_value',
      key: 'max_value',
      width: '10%',
      render: (_, record) => {
        return record?.transaction?.transaction_date ? dayjs(record?.transaction?.transaction_date).format('DD-MM-YYYY') : '-';
      },
    },
    {
      title: 'Property Type',
      dataIndex: 'property_type',
      key: 'property_type',
      width: '15%',
      render: (_, record) => {
        return getPropertyTypeLabel(record?.transaction?.property?.property_type);
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: (_, record) => {
        return (
          <button className="btn btn-primary px-2 mt-xxl-0 mt-2" onClick={() => handleClickCompleted(record)}>
            <PencilIcon className="img-fluid" />
          </button>
        );
      },
    },
  ];

  const handleClickCompleted = (record) => {
    localStorage.setItem('rentCompletedFilter', JSON.stringify(filter));
    localStorage.setItem('rentCompletedCurrentPage', JSON.stringify(paginationCurrent));
    navigate(`/review-rent/${record?.transaction?.id}`, {
      state: { UserId: record?.user_id, completed: true },
    });
  };

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter({ startDate: '', endDate: '', searchSlug: '' });
    setInputValue('');
    setPaginationCurrent(1);
  };

  const handleTableChange = (pagination) => {
    setPaginationCurrent(pagination.current);
  };

  return (
    <>
      <div className="d-flex gap-3 flex-sm-column loans-header-responsive flex-md-row flex-lg-row justify-content-between align-items-center">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Rent Summary Completed</h5>
          </div>
        </div>
        <div className="reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex loans-header-responsive align-items-center gap-2 justify-content-end">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search" className="p-1 m-0">
                    <Input onChange={(e) => SearchSlug(e)} value={inputValue} allowClear style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Button type="primary" icon={<ReloadOutlined />} size="large" className="reset-width-100 d-flex align-items-center" onClick={() => resetFilter()}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="dashboard_list">
              <Table columns={RentCompleteColumn} pagination={{ current: paginationCurrent }} dataSource={completeSummaryData} rowKey={(record) => record.id} onChange={handleTableChange} />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default RentCompleted;
