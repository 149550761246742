import { TagsOutlined } from '@ant-design/icons';
import { DashboardIcon, DepreciationIcon, PropertySettlementIcon, PropertyValuationsIcon, RentSummaryIcon, RentSummaryNoMarginIcon, UsersIcon } from '../assets/icons';

const NavigationConfig = [
  {
    title: 'Dashboard',
    path: 'dashboard',
    icon: <DashboardIcon />,
  },
  {
    title: 'Depreciation',
    path: 'depreciation/pending',
    icon: <DepreciationIcon />,
  },
  {
    title: 'Property Settlement',
    path: 'property-settlement/pending',
    icon: <PropertySettlementIcon />,
  },
  {
    title: 'Property Valuation',
    path: 'property-valuation',
    icon: <PropertyValuationsIcon />,
  },
  {
    title: 'Rent Summary',
    path: 'rent/pending',
    icon: <RentSummaryNoMarginIcon />,
  },
  {
    title: 'Users',
    path: 'users',
    icon: <UsersIcon />,
  },
  {
    title: 'User Loans',
    path: 'loans',
    icon: <PropertyValuationsIcon />,
  },

  {
    title: 'Notification',
    path: 'notification',
    icon: <RentSummaryIcon />,
  },
  {
    title: 'Coupon',
    path: 'coupons',
    icon: (
      <TagsOutlined
        style={{
          fontSize: '20px',
          marginEnd: '10px',
        }}
      />
    ),
  },
  // {
  //   title: 'Market Value',
  //   path: 'market-value',
  // },
  // {
  //   title: 'Subscription History',
  //   path: 'subscription/history',
  // },
  // {
  //   title: 'Query',
  //   path: 'end-of-year/pending',
  //   icon: <PaperClipOutlined />,
  // },
];

export default NavigationConfig;
