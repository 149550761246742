import React, { useState } from 'react';
import { Button, DatePicker, Drawer, Form, InputNumber, notification, Radio, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchUserDiscountAsync, setUserDiscountAsync } from '../../container/reducers/slices/UserDiscount/UserDiscountSlice';

const { RangePicker } = DatePicker;

const AddUserDiscount = ({ id = null, openAdd = false, setOpenAdd = () => {} }) => {
  const [discountType, setDiscountType] = useState('percentage');
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenAdd(false);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    try {
      const res = await dispatch(setUserDiscountAsync());
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(fetchUserDiscountAsync(id));
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  return (
    <Drawer destroyOnClose title="Subscription Discount" placement="right" onClose={handleClose} open={openAdd} width={650}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="discountType" label="Discount Type" initialValue={'percentage'}>
          <Radio.Group onChange={(e) => setDiscountType(e.target.value)} value={discountType}>
            <Radio value="percentage">Percentage</Radio>
            <Radio value="flat">Flat Amount</Radio>
          </Radio.Group>
        </Form.Item>

        {discountType === 'percentage' && (
          <Form.Item name="discountPercentage" label="Discount Percentage" rules={[{ required: true, message: 'Enter a discount value' }]}>
            <InputNumber min={0} max={100} placeholder="Enter percentage" style={{ width: '100%' }} size="large" />
          </Form.Item>
        )}

        {discountType === 'flat' && (
          <Form.Item name="discountValue" label="Flat Discount Amount" rules={[{ required: true, message: 'Enter a discount value' }]}>
            <InputNumber min={0} placeholder="Enter amount" style={{ width: '100%' }} size="large" />
          </Form.Item>
        )}

        <Form.Item name="validDateRange" label="Discount Validity" rules={[{ required: true, message: 'Discount Validity is required' }]}>
          <RangePicker format={['DD-MM-YYYY', 'DD-MM-YYYY']} />
        </Form.Item>

        <Form.Item name="applyDateRange" label="Apply Validity" rules={[{ required: true, message: 'Apply Validity is required' }]}>
          <RangePicker format={['DD-MM-YYYY', 'DD-MM-YYYY']} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create Discount
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddUserDiscount;
