import { Button, notification, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { deleteUserDiscountAsync, fetchUserDiscountAsync, selectUserDiscount, selectUserDiscountLoading } from '../../container/reducers/slices/UserDiscount/UserDiscountSlice';
import AddUserDiscount from './AddUserDiscount';
import { DeleteOutlined } from '@ant-design/icons';

export default function UserDiscount() {
  const [discountData, setDiscountData] = useState([{ name: 'hello', value: 0 }]);

  const [addOpen, setAddOpen] = useState(false);

  const { id } = useParams();

  const dispatch = useDispatch();

  const userDiscount = useSelector(selectUserDiscount);
  const loading = useSelector(selectUserDiscountLoading);

  useEffect(() => {
    if (id) {
      dispatch(fetchUserDiscountAsync(id));
    }
  }, [dispatch, id]);

  const handleDelete = async (id) => {
    try {
      const res = await dispatch(deleteUserDiscountAsync(id));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(fetchUserDiscountAsync(id));
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const items = [
    {
      key: '1',
      label: 'Discount Type',
      children: <p>Direct</p>,
    },
    {
      key: '2',
      label: 'Amount',
      children: <p>1810000000</p>,
    },
    {
      key: '3',
      label: 'Discount Validity',
      children: <p>DD-MM-YYYY - DD-MM-YYYY</p>,
    },
    {
      key: '4',
      label: 'Apply Validity',
      children: <p>DD-MM-YYYY - DD-MM-YYYY</p>,
    },
  ];

  // const columns = [
  //   {
  //     title: 'Discount Type',
  //     dataIndex: 'discountType',
  //     key: 'discountType',
  //   },
  //   {
  //     title: 'Percentage/Amount',
  //     dataIndex: 'amount',
  //     key: 'amount',
  //   },
  //   {
  //     title: 'Discount Validity',
  //     dataIndex: 'discountValidity',
  //     key: 'discountValidity',
  //   },
  //   {
  //     title: 'Apply Validity',
  //     dataIndex: 'applyValidity',
  //     key: 'applyValidity',
  //   },
  //   {
  //     title: 'Action',
  //     dataIndex: 'action',
  //     render: (text, record) => {
  //       return (
  //         <Popconfirm title="Are you sure you want to delete?" onConfirm={() => handleDelete(record.id)} okText="Yes" cancelText="No">
  //           <Button
  //             className="Delete_btn"
  //             icon={
  //               <DeleteOutlined
  //                 style={{
  //                   fontSize: 18,
  //                   paddingTop: 3,
  //                 }}
  //               />
  //             }
  //             type="primary"
  //           />
  //         </Popconfirm>
  //       );
  //     },
  //   },
  // ];

  const columns = [
    {
      title: 'Discount Type',
      dataIndex: 'discountType',
      key: 'discountType',
    },
    {
      title: 'Percentage/Amount',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Valid From',
      dataIndex: 'validFrom',
      key: 'validFrom',
    },
    {
      title: 'Valid To',
      dataIndex: 'validTo',
      key: 'validTo',
    },
    {
      title: 'Apply From',
      dataIndex: 'applyFrom',
      key: 'applyFrom',
    },
    {
      title: 'Apply To',
      dataIndex: 'applyTo',
      key: 'applyTo',
    },
    {
      title: 'Discount (%) / Amount ($)',
      dataIndex: 'discount',
      key: 'discount',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <Popconfirm title="Are you sure you want to delete?" onConfirm={() => handleDelete(record.id)} okText="Yes" cancelText="No">
            <Button
              className="Delete_btn"
              icon={
                <DeleteOutlined
                  style={{
                    fontSize: 18,
                    paddingTop: 3,
                  }}
                />
              }
              type="primary"
            />
          </Popconfirm>
        );
      },
    },
  ];

  const handleCreate = () => {
    setAddOpen(true);
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-end mb-3">
        <Button type="primary" onClick={handleCreate}>
          + Add
        </Button>
      </div>
      <Table dataSource={discountData} columns={columns} loading={false} />

      <AddUserDiscount openAdd={addOpen} setOpenAdd={setAddOpen} id={id} />
    </div>
  );
}
