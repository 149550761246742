import React from 'react';
import { Button, DatePicker, Form, Input, InputNumber, Modal, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { addUserCouponAsync, fetchUserCouponAsync } from '../../container/reducers/slices/UserCoupon/UserCouponsSlice';
const { RangePicker } = DatePicker;

const AddCoupoun = ({ open = false, setOpen = () => {} }) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    try {
      const res = await dispatch(addUserCouponAsync(values));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(fetchUserCouponAsync());
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <Modal onCancel={handleCancel} footer={null} centered style={{ borderRadius: '1em' }} destroyOnClose open={open} title="Add Coupon">
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="couponName" label="Coupon Name" rules={[{ required: true, message: 'Please enter coupon name' }]}>
          <Input placeholder="Coupon Name" size="large" />
        </Form.Item>

        <Form.Item name="validDateRange" label="Discount Validity" rules={[{ required: true, message: 'Discount Validity is required' }]}>
          <RangePicker format={['DD-MM-YYYY', 'DD-MM-YYYY']} />
        </Form.Item>

        <Form.Item name="applyDateRange" label="Apply Validity" rules={[{ required: true, message: 'Apply Validity is required' }]}>
          <RangePicker format={['DD-MM-YYYY', 'DD-MM-YYYY']} />
        </Form.Item>

        <Form.Item name="discount" label="Discount" rules={[{ required: true, message: 'Please enter discount' }]}>
          <InputNumber min={0} max={100} required placeholder="Discount Percentage" size="large" className="w-100" />
        </Form.Item>

        <Form.Item className="d-flex justify-content-center">
          <Button type="primary" htmlType="submit">
            Create Coupon
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCoupoun;
