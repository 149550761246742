import dashboardSVG from '../images/Home.svg';
import depreciationSVG from '../images/depreciation.svg';
import propertySettlementSVG from '../images/hand-shake.svg';
import propertyValuationsSVG from '../images/dollar-house.svg';
import appSVG from '../images/logo.svg';
import appFaviconImg from '../images/favicon .ico';
import eyeSVG from '../images/eye.svg';
import plusSVG from '../images/plus.svg';
import deleteSVG from '../images/trash.svg';
import pencilSVG from '../images/edit (1).svg';
import userSVG from '../images/user.svg';
import lockSVG from '../images/lock.svg';
import eyeOffSVG from '../images/eye-off.svg';
import blackEyeSVG from '../images/eye-black.svg';
import dollarSVG from '../images/dollar-sign.svg';
import usersTab from '../images/usersTab.svg';
import rentSummarySvg from '../images/rentSummary.svg';
import Templates from '../images/Templates.svg';
import Filter from '../images/Filter.svg';
import Group from '../images/Group.svg';
import PropertyImage from '../images/property.svg';
import RentImage from '../images/rent.svg';
import DepreciationOverview from '../images/depreciationoverview.svg';
import PropertySetlementOverview from '../images/hand-shake-overview.svg';

export const RentIcon = () => {
  return <img src={RentImage} alt="Property icon" />;
};

export const PropertyIcon = () => {
  return <img src={PropertyImage} className="propertyImgIcon" alt="Property icon" />;
};

export const FilterIcon = () => {
  return <img src={Filter} className="me-3" alt="Filter icon" />;
};

export const GroupIcon = () => {
  return <img src={Group} className="me-3" alt="Group icon" />;
};

export const TemplatesIcon = () => {
  return <img src={Templates} className="me-3" alt="Templates icon" />;
};

export const AppIcon = (props) => {
  return <img src={appSVG} alt="app__logo" {...props} />;
};
export const AppFavicon = (props) => {
  return <img src={appFaviconImg} alt="app__logo" {...props} />;
};

export const DashboardIcon = () => {
  return <img src={dashboardSVG} className="me-3" alt="dashboard icon" />;
};

export const DepreciationIcon = () => {
  return <img src={depreciationSVG} className="me-3" alt="depreciation icon" />;
};

export const DepreciationIconOverview = () => {
  return <img src={DepreciationOverview} alt="depreciation icon" />;
};

export const PropertySettlementIcon = () => {
  return <img src={propertySettlementSVG} className="me-3" alt="property settlement icon" />;
};

export const PropertySettlementIconOverview = () => {
  return <img src={PropertySetlementOverview} alt="property settlement icon" />;
};

export const PropertyValuationsIcon = () => {
  return <img src={propertyValuationsSVG} className="me-3" alt="property valuations icon" />;
};
export const UsersIcon = () => {
  return <img src={usersTab} className="me-3" alt="user icon" />;
};
export const UsersIconNoMargin = () => {
  return <img src={usersTab} alt="user icon" />;
};
export const RentSummaryIcon = () => {
  return <img src={rentSummarySvg} className="me-3" alt="rent summary icon" />;
};
export const RentSummaryNoMarginIcon = () => {
  return <img src={rentSummarySvg} className="me-3" alt="rent summary icon" />;
};
export const EyeIcon = (props) => {
  return <img src={eyeSVG} {...props} alt="eye icon" />;
};

export const BlackEyeIcon = (props) => {
  return <img src={blackEyeSVG} {...props} alt="black eye icon" />;
};

export const EyeOffIcon = (props) => {
  return <img src={eyeOffSVG} {...props} alt="eye off icon" />;
};

export const PlusIcon = (props) => {
  return <img src={plusSVG} {...props} alt="plus icon" />;
};

export const DeleteIcon = (props) => {
  return <img src={deleteSVG} {...props} alt="delete icon" />;
};

export const PencilIcon = (props) => {
  return <img src={pencilSVG} {...props} alt="pencil icon" />;
};

export const UserIcon = (props) => {
  return <img src={userSVG} {...props} alt="user icon" />;
};

export const LockIcon = (props) => {
  return <img src={lockSVG} {...props} alt="lock icon" />;
};

export const DollarIcon = (props) => {
  return <img src={dollarSVG} {...props} alt="dollar icon" />;
};
