import { DatePicker, Form, Input, Spin, Table, notification, Button, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { deleteUserCouponAsync, fetchUserCouponAsync, selectUserCoupon, selectUserCouponLoading } from '../../container/reducers/slices/UserCoupon/UserCouponsSlice';
import AddCoupoun from './AddCoupoun';

const Coupon = () => {
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    searchSlug: '',
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });
  const [total, setTotal] = useState(0);

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const coupons = useSelector(selectUserCoupon);
  const loading = useSelector(selectUserCouponLoading);

  useEffect(() => {
    dispatch(fetchUserCouponAsync());
  }, [filter, dispatch, pagination]);

  const startDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.endDate) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greater then End Date.',
      });
      filterForm.setFieldsValue({ startDate: '' });
      return;
    }
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, startDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const endDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.startDate) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greater then End Date.',
      });
      filterForm.setFieldsValue({ endDate: '' });
      return;
    }
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, endDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const SearchSlug = debounce((e) => {
    const values = e.target.value.trim();
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, searchSlug: values });
  }, 300);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleDelete = async (id) => {
    try {
      const res = await dispatch(deleteUserCouponAsync(id));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(fetchUserCouponAsync(id));
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const columns = [
    {
      title: 'Coupon Code',
      dataIndex: 'couponCode',
      key: 'couponCode',
      render: (text) => text || '-',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Valid From',
      dataIndex: 'validFrom',
      key: 'validFrom',
    },
    {
      title: 'Valid To',
      dataIndex: 'validTo',
      key: 'validTo',
    },
    {
      title: 'Apply From',
      dataIndex: 'applyFrom',
      key: 'applyFrom',
    },
    {
      title: 'Apply To',
      dataIndex: 'applyTo',
      key: 'applyTo',
    },
    {
      title: 'Discount %',
      dataIndex: 'discount',
      key: 'discount',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <Popconfirm title="Are you sure you want to delete?" onConfirm={() => handleDelete(record.id)} okText="Yes" cancelText="No">
            <Button
              className="Delete_btn"
              icon={
                <DeleteOutlined
                  style={{
                    fontSize: 18,
                    paddingTop: 3,
                  }}
                />
              }
              type="primary"
            />
          </Popconfirm>
        );
      },
    },
  ];

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter({
      startDate: '',
      endDate: '',
      searchSlug: '',
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row  justify-content-between">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Coupons</h5>
          </div>
        </div>
        <div className="date_picker reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex  loans-header-responsive justify-content-between align-items-center gap-3">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search" className="p-1 m-0">
                    <Input onChange={(e) => SearchSlug(e)} style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Button icon={<ReloadOutlined />} type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={() => resetFilter()}>
                    Reset
                  </Button>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Button type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={handleOpen}>
                    + Add
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table columns={columns} pagination={{ ...pagination, total: total }} dataSource={[]} onChange={handleTableChange} loading={loading} />
          </div>
        </div>
      </div>
      <AddCoupoun open={open} setOpen={setOpen} />
    </>
  );
};

export default Coupon;
